import * as React from "react";
import { useLocation } from "@reach/router";

import { SEO } from "../components/seo";
import Page from "../components/Page/Page";
import TopBar from "../components/TopBar/TopBar";
import Section from "../components/Section/Section";
import SectionHeading from "../components/SectionHeading/SectionHeading";
import Footer from "../components/Footer/Footer";

import "./LegalPage.css";

const AccessibilityPage = ({ pageContext }) => {
  return (
    <Page>
      <TopBar showSearch={true} hideCurrency={true} />
      <div style={{ maxWidth: 1000, margin: "0 auto" }}>
        <Section>
          <SectionHeading>Accessibility</SectionHeading>
          <div style={{ maxWidth: 900 }}>
            <p>
              <span style={{ color: "#374151" }}>
                Accessibility at Travel Lingual
              </span>
            </p>
            <p>
              <span style={{ color: "#374151" }}>
                At Travel Lingual, we are committed to providing a welcoming and
                accessible online experience for all visitors, regardless of
                their abilities or disabilities. We strive to ensure that
                everyone can access and enjoy the content and features of our
                website.
              </span>
            </p>
            <p>
              <span style={{ color: "#374151" }}>
                Our Commitment to Accessibility
              </span>
            </p>
            <p>
              <span style={{ color: "#374151" }}>
                We believe that accessibility is not just a legal requirement
                but also a fundamental aspect of providing a positive user
                experience. We are dedicated to making continuous improvements
                to meet or exceed the Web Content Accessibility Guidelines
                (WCAG) 2.1 AA standards.
              </span>
            </p>
            <p>
              <span style={{ color: "#374151" }}>What We're Doing</span>
            </p>
            <p>
              <span style={{ color: "#374151" }}>
                Here are some of the steps we've taken to enhance the
                accessibility of Travel Lingual:
              </span>
            </p>
            <ul>
              <li>
                <span style={{ color: "#374151" }}>
                  Web Design: Our website is designed with accessibility in
                  mind, including clear navigation, easy-to-read fonts, and
                  adaptable layouts that work well with various screen readers
                  and devices.
                </span>
              </li>
              <li>
                <span style={{ color: "#374151" }}>
                  Alt Text: We provide descriptive alt text for all images,
                  ensuring that users with visual impairments can understand the
                  content.
                </span>
              </li>
              <li>
                <span style={{ color: "#374151" }}>
                  Keyboard Navigation: We have optimized our site for keyboard
                  navigation, making it easier for individuals who cannot use a
                  mouse.
                </span>
              </li>
              <li>
                <span style={{ color: "#374151" }}>
                  Captions and Transcripts: Videos on our platform are
                  captioned, and transcripts are provided for audio content.
                </span>
              </li>
              <li>
                <span style={{ color: "#374151" }}>
                  Readable Content: We aim to maintain clear and concise content
                  that is easy to understand.
                </span>
              </li>
              <li>
                <span style={{ color: "#374151" }}>
                  User Feedback: We welcome feedback from our users regarding
                  any accessibility issues they encounter. Please contact us if
                  you have suggestions for improvement.
                </span>
              </li>
            </ul>
            <p>
              <span style={{ color: "#374151" }}>Accessibility Tools</span>
            </p>
            <p>
              <span style={{ color: "#374151" }}>
                We recommend the use of the following tools and features that
                can help users customize their browsing experience:
              </span>
            </p>
            <ul>
              <li>
                <span style={{ color: "#374151" }}>
                  Screen Readers: Users with visual impairments can benefit from
                  screen reader software such as JAWS, NVDA, and VoiceOver.
                </span>
              </li>
              <li>
                <span style={{ color: "#374151" }}>
                  Keyboard Shortcuts: Many browsers offer keyboard shortcuts to
                  make navigation easier.
                </span>
              </li>
              <li>
                <span style={{ color: "#374151" }}>
                  Browser Zoom: You can adjust the zoom settings in your web
                  browser to make text and images larger.
                </span>
              </li>
            </ul>
            <p>
              <span style={{ color: "#374151" }}>Contact Us</span>
            </p>
            <p>
              <span style={{ color: "#374151" }}>
                If you encounter any accessibility issues while using our
                website or have suggestions for improvement, please contact us.
                Your feedback is valuable as we strive to make Travel Lingual
                more accessible and user-friendly for all visitors.
              </span>
            </p>
            <p>
              <span style={{ color: "#374151" }}>Accessibility Statement</span>
            </p>
            <p>
              <span style={{ color: "#374151" }}>
                This accessibility statement was last updated on [Date]. We will
                review and update our accessibility efforts regularly to ensure
                that all visitors can enjoy the content and services we provide.
              </span>
            </p>
            <p>
              <span style={{ color: "#374151" }}>
                Thank you for choosing Travel Lingual, and we appreciate your
                support in our commitment to accessibility for all.
              </span>
            </p>
          </div>
        </Section>
      </div>
      <Footer />
    </Page>
  );
};

export default AccessibilityPage;

export const Head = () => {
  const location = useLocation();
  return (
    <SEO
      index
      follow
      title={"Accessibility | Travel-Lingual"}
      description={
        "Accessibility at Travel Lingual. At Travel Lingual, we are committed to providing a welcoming and accessible online experience for all visitors, regardless of their abilities."
      }
      pathname={location.pathname}
    />
  );
};
