import React from "react";
import { useSiteMetadata } from "../hooks/use-site-metadata";

export const SEO = ({
  title,
  description,
  pathname,
  children,
  index,
  follow,
  publishedDate,
}) => {
  const {
    title: defaultTitle,
    description: defaultDescription,
    image,
    siteUrl,
    twitterUsername,
  } = useSiteMetadata();

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image}`,
    url: `${siteUrl}${pathname || ``}`,
    twitterUsername,
  };

  const date = new Date(publishedDate);

  let year = date.getFullYear();
  let month = ("0" + (date.getMonth() + 1)).slice(-2);
  let day = ("0" + date.getDate()).slice(-2);

  return (
    <>
      <title>{seo.title}</title>
      <meta
        name={`robots`}
        content={`${index ? `index` : `noindex`},${
          follow ? `follow` : `nofollow`
        }`}
      />
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:url" content={seo.url} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />
      <meta name="twitter:creator" content={seo.twitterUsername} />
      <meta name="publish-date" content={`${year}-${month}-${day}`} />
      {children}
    </>
  );
};
